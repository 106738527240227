import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import { OutboundLink } from "./OutboundLink.js"

import "./providers.scss"

class Providers extends React.Component {
  render() {
    const fronius = getImage(this.props.fronius)
    const schneider = getImage(this.props.schneider)
    const rhona = getImage(this.props.rhona)
    const jinko = getImage(this.props.jinko)
    return (
      <div id="providers">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-12 col-md-6 text-center">
              <OutboundLink href="http://www.fronius.com/">
                <GatsbyImage
                  className="provider-logo"
                  image={fronius}
                  alt="Logo Fronius"
                />
              </OutboundLink>
            </div>
            <div className="col-lg-3 col-sm-12 col-md-6 text-center">
              <OutboundLink href="http://solar.schneider-electric.com/">
                <GatsbyImage
                  className="provider-logo"
                  image={schneider}
                  alt="Logo Schneider"
                />
              </OutboundLink>
            </div>
            <div className="col-lg-3 col-sm-12 col-md-6 text-center">
              {/*<OutboundLink href='http://www.rhona.cl/'>*/}
              <GatsbyImage
                className="provider-logo"
                image={rhona}
                alt="Logo Rhona"
              />
              {/*</OutboundLink>*/}
            </div>
            <div className="col-lg-3 col-sm-12 col-md-6 text-center">
              <OutboundLink href="http://www.jinkosolar.com">
                <GatsbyImage
                  className="provider-logo"
                  image={jinko}
                  alt="Logo Jinko"
                />
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        fronius: file(relativePath: { regex: "/fronius.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 200)
          }
        }
        schneider: file(relativePath: { regex: "/schneider.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 200)
          }
        }
        rhona: file(relativePath: { regex: "/rhona.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 200)
          }
        }
        jinko: file(relativePath: { regex: "/jinko-solar-logo.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 200)
          }
        }
      }
    `}
    render={data => (
      <Providers
        fronius={data.fronius}
        schneider={data.schneider}
        rhona={data.rhona}
        jinko={data.jinko}
        {...props}
      />
    )}
  />
)
