import React, { Component } from "react"
import "../../node_modules/charts.css/dist/charts.min.css"

var buyerData = {
  labels: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  datasets: [
    {
      backgroundColor: "rgba(125,193,80,0.5)",
      data: [
        -177362, -205458, -150137, -249599, -87120, 37462, 100551, 151952,
        286117, -91912, -173659, -126977,
      ],
      label: "Con energía solar",
    },
    {
      backgroundColor: "rgba(0,50,171,0.5)",
      data: [
        44213, 76956, 234716, 271088, 509289, 710609, 769415, 683239, 778417,
        253737, 62291, 62509,
      ],
      label: "Sin energía solar",
    },
  ],
}

var options = {
  title: {
    display: true,
    text: "Ahorro mensual en cuenta eléctrica",
  },
  tooltips: {
    enabled: false,
  },
  legend: {
    display: true,
  },
  scales: {
    xAxis: {
      stacked: false,
    },
    yAxis: {
      ticks: {
        min: -400000,
        max: 1000000,
        //stepWidth: 2
      },
      scaleLabel: {
        display: true,
        labelString: "Gasto mensual en pesos",
      },
    },
  },
}

export default class ElectricBill extends Component {
  render() {
    const max = Math.max(...buyerData.datasets[0].data)
    return (
      <>
        <table
          className="charts-css column multiple data-spacing-7"
          id="solar-project"
        >
          <tbody>
            {buyerData.labels.map((label, index) => {
              const value0 = buyerData.datasets[0].data[index]
              const value1 = buyerData.datasets[1].data[index]

              const size1 = value1 / max
              let size0 = 0
              if (value0 > 0) size0 = value0 / max

              return (
                <tr key={label}>
                  <td className="green-energy" style={{ "--size": size0 }} />
                  <td className="no-green-energy" style={{ "--size": size1 }} />
                </tr>
              )
            })}
          </tbody>
        </table>
        <table
          className="charts-css column multiple reverse data-spacing-7 show-labels"
          id="solar-project-reverse"
        >
          <tbody>
            {buyerData.labels.map((label, index) => {
              const value0 = buyerData.datasets[0].data[index]

              const size1 = 0
              let size0 = 0
              if (value0 < 0) size0 = Math.abs(value0) / max

              return (
                <tr key={label}>
                  {/*index % 15 == 0 ? <th scope="row">{label}</th> : null*/}
                  <td className="green-energy" style={{ "--size": size0 }} />
                  <td className="no-green-energy" style={{ "--size": size1 }} />
                  <th scope="row">{label}</th>
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }
}
