import React, { Component } from "react"
import "../../node_modules/charts.css/dist/charts.min.css"

var buyerData = {
  labels: [
    "Oct 1982",
    "Abr 1983",
    "Oct 1983",
    "Abr 1984",
    "Oct 1984",
    "Abr 1985",
    "Oct 1985",
    "Abr 1986",
    "Oct 1986",
    "Abr 1987",
    "Oct 1987",
    "Abr 1988",
    "Oct 1988",
    "Abr 1989",
    "Oct 1989",
    "Abr 1990",
    "Oct 1990",
    "Abr 1991",
    "Oct 1991",
    "Abr 1992",
    "Oct 1992",
    "Abr 1993",
    "Oct 1993",
    "Abr 1994",
    "Oct 1994",
    "Abr 1995",
    "Oct 1995",
    "Abr 1996",
    "Oct 1996",
    "Abr 1997",
    "Oct 1997",
    "Abr 1998",
    "Oct 1998",
    "Abr 1999",
    "Oct 1999",
    "Abr 2000",
    "Oct 2000",
    "Abr 2001",
    "Oct 2001",
    "Abr 2002",
    "Oct 2002",
    "Abr 2003",
    "Oct 2003",
    "Abr 2004",
    "Oct 2004",
    "Abr 2005",
    "Oct 2005",
    "Abr 2006",
    "Oct 2006",
    "Abr 2007",
    "Oct 2007",
    "Abr 2008",
    "Oct 2008",
    "Abr 2009",
    "Oct 2009",
    "Abr 2010",
    "Oct 2010",
    "Abr 2011",
    "Oct 2011",
    "Abr 2012",
    "Oct 2012",
    "Abr 2013",
    "Oct 2013",
    "Abr 2014",
    "Oct 2014",
    "Abr 2015",
    "Oct 2015",
    "Abr 2016",
  ],
  datasets: [
    {
      fill: true,
      backgroundColor: "rgba(125,193,80,0.5)",
      data: [
        1.78, 1.89, 2.11, 2.1, 2.61, 3.05, 3.47, 3.96, 4.01, 4.55, 5.49, 6.5,
        7.22, 8.13, 8.86, 10.38, 9.66, 10.66, 9.83, 9.91, 11.07, 12.71, 13.41,
        14.11, 15.31, 15.56, 14.53, 14.76, 12.9, 13.7, 11.31, 10.44, 9.99,
        10.63, 10.91, 11.8, 14.85, 15.58, 17.18, 15.6, 16.08, 16.8, 16.75,
        18.93, 19.14, 19.45, 27.11, 26, 28.74, 31.62, 45.9, 45.25, 54.58, 49.11,
        41.73, 41.01, 47.25, 45.67, 44.66, 45.4, 45.2, 40.95, 45.77, 47.56,
        50.77, 51.3, 49.48, 43.88,
      ],
      label: "precio de la electricidad",
    },
  ],
}

var options = {
  scales: {
    xAxis: {
      type: "time",
      ticks: {
        displayFormats: {
          month: "MMM YYYY",
        },
        min: "1982-04-01",
        max: "2016-04-01",
      },
    },
    yAxis: {
      scaleLabel: {
        display: true,
        labelString: "Energía Nominal ($/kWh)",
        fontSize: 16,
      },
      ticks: {
        beginAtZero: true,
        display: true,
      },
    },
  },
  title: {
    display: true,
    text: "Precio de Nudo de Corto Plazo SIC (Alto Jahuel 220)",
    fontSize: 16,
  },
  tooltips: {
    enabled: false,
  },
  legend: {
    display: false,
  },
}

export default class PriceVolatility extends Component {
  render() {
    const max = Math.max(...buyerData.datasets[0].data)
    const min = Math.min(...buyerData.datasets[0].data)
    return (
      <>
        <table
          className="charts-css area show-heading show-labels"
          id="ine-chart"
        >
          <caption className="text-center">
            Indices de Precios de la Electricidad en Chile
          </caption>
          <tbody>
            {buyerData.labels.map((label, index) => {
              let value = buyerData.datasets[0].data[index]

              let start = 0
              if (index > 0) {
                start =
                  (buyerData.datasets[0].data[index - 1] - min) / (max - min)
              }

              const size = (value - min) / (max - min)
              value = Math.round(value)
              return (
                <tr key={label}>
                  {index % 15 == 0 ? <th scope="row">{label}</th> : null}
                  <td style={{ "--start": start, "--size": size }}>
                    {/*index % 3 == 0 ? (
                    <span className="data">{value}</span>
                  ) : null*/}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <p className="ine-caption">Fuente: CNE, 2016</p>
      </>
    )
  }
}
