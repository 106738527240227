import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticQuery, graphql, navigate } from "gatsby"

import Slide from "../components/Slide"
import Providers from "../components/Providers"
import ElectricBill from "../components/ElectricBill"
import PriceVolatility from "../components/PriceVolatility"
import NavBar from "../components/NavBar"
import Jumbo from "../components/Jumbo"
import Footer from "../components/Footer"

export function Head() {
  return (
    <>
      <title>Paneles y energia solar para empresas - Chile</title>
      <meta
        name="description"
        content="Transforma la energia solar en tu ventaja competitiva. Eficiencia energetica por medio de plantas de paneles solares para empresas e industrias."
      />
      <meta
        name="keywords"
        content="Empresa solar, Empresa paneles fotovoltaicos, mipyme, paneles solares pyme, proyectos energia solar, energia solar en empresas, instalacion paneles empresas, paneles industria"
      />
    </>
  )
}

class Empresas extends React.Component {
  constructor(props) {
    super(props)
    this.click = this.click.bind(this)
  }

  click(e) {
    e.preventDefault()
    navigate("/miplanta?tipo=empresas")
  }

  render() {
    const lideres = getImage(this.props.lideres)
    const internacional = getImage(this.props.internacional)
    return (
      <>
        <NavBar {...this.props} />
        <Jumbo jumbo={this.props.jumbo}>
          <h1>Empresa Solar</h1>
          <h2>tu ventaja competitiva</h2>
        </Jumbo>
        <div className="copyright">
          <div className="col-md-12 col-xs-12 text-center">
            <a href="#lideres">
              <i className="fa-solid fa-angle-down fa-bounce"></i>
            </a>
          </div>
        </div>

        <section id="empresas">
          <div className="container">
            <Slide id="lideres" nextSlide="#operacion">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="text-center">
                  <h2>
                    <b>Líderes</b> en energías limpias
                  </h2>
                  <h4>
                    Desarrollo de proyectos solares comerciales con respaldo de
                    35 años de experiencia
                  </h4>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 vcenter">
                <GatsbyImage
                  image={lideres}
                  alt="Líderes en energía solar e instalación de paneles solares"
                />
              </div>
              <div className="col-sm-12 col-md-6 vcenter">
                <p>
                  GlobalAxxis es el líder en servicios de energía limpia y
                  proveedor número uno de sistemas solares fotovoltaicos
                  regularizados en Chile. Nuestros especialistas de la más alta
                  preparación técnica y nuestra tecnología de clase mundial son
                  sólo algunas de las ventajas en energía solar que nos
                  convierten en la compañía de preferencia de pequeñas y
                  medianas empresas, establecimientos educacionales y entidades
                  gubernamentales.
                </p>

                <p>
                  Nuestros sistemas de energía solar superan constantemente sus
                  expectativas de generación, y junto a nuestro cumplimiento
                  record de fechas de entrega y presupuestos, nos han otorgado
                  un prestigio de calidad con nuestros clientes.
                </p>
              </div>
            </Slide>

            <Slide id="operacion" nextSlide="#calidad">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="text-center">
                  <h2>
                    <b>Reduce</b> tus costos de operación
                  </h2>
                  <h4>
                    Te ofrecemos la habilidad de reducir, eliminar o recibir
                    pagos de la compañía distribuidora. La energía solar posee
                    la ventaja de asegurar costos mínimos y predecibles en el
                    largo plazo, evitando la volatilidad siempre en subida del
                    mercado energético.
                  </h4>
                </div>
              </div>

              <div className="col-lg-6 col-12 my-auto">
                Todos los negocios, independientes de su tamaño, pueden verse
                favorecidos al capitalizar las oportunidades financieras que
                ofrecen las plantas solares. Estos son algunos de sus múltiples
                beneficios:
                <ul className="bullet-list bullet-list-wide">
                  <li>Ahorro en gastos de consumo eléctrico</li>
                  <li>
                    Estabilidad y protección ante la volatilidad del precio de
                    la electricidad
                  </li>
                  <li>Independencia y autonomía energética</li>
                  <li>Muy bajos costos de mantención</li>
                  <li>
                    Las plantas son depreciables contablemente, permitiendo
                    disminuir en un 25% los impuestos a pagar cada año cargados
                    la depreciación
                  </li>{" "}
                  <li>Aumento de valor del terreno o propiedad</li>
                  <li>
                    Inversión económicamente rentable bajo cualquier estándar
                  </li>
                  <li>
                    Imagen corporativa moderna, renovada y preocupada por el
                    medio ambiente
                  </li>
                </ul>
              </div>

              <div className="col-lg-6 col-12 my-auto">
                <PriceVolatility />
              </div>
            </Slide>

            <Slide id="calidad" nextSlide="#ejemplo">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="text-center">
                  <h2>
                    Calidad <b>internacional</b>
                  </h2>
                  <h4>
                    GlobalAxxis es la empresa líder en servicios de energía
                    fotovoltaica y el proveedor #1 en Chile de plantas
                    generadoras solares regularizadas ante la S.E.C.
                  </h4>
                </div>
              </div>

              <div className="col-sm-12 col-md-6">
                <GatsbyImage
                  image={internacional}
                  alt="Paneles solares de alta calidad internacional"
                />
              </div>
              <div className="col-sm-12 col-md-6 vcenter">
                <p>
                  Como parte de nuestra misión nos hemos planteado ofrecer el
                  mejor producto disponible en el mercado a nuestros clientes.
                  Por ello, nosotros trabajamos exclusivamente con los mejores
                  fabricantes del sector. En términos de calidad, no transamos.
                </p>
              </div>
            </Slide>
          </div>

          <Providers />

          <div className="container">
            <Slide id="ejemplo" nextSlide="#visita">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="text-center">
                  <h2>
                    Ejemplo de mediana empresa <b>solar</b>
                  </h2>
                  <h4>
                    Solar es sinónimo de inversión, y nosotros en GlobalAxxis,
                    ofrecemos una variedad de soluciones para empresas y
                    corporaciones de todos los tamaños
                  </h4>
                </div>
              </div>

              <div className="col-md-12 col-lg-6" style={{ height: "50vh" }}>
                <span
                  className="row justify-content-center"
                  style={{ fontSize: "20px", color: "rgb(108, 117, 125)" }}
                >
                  Gastos en electricidad
                </span>
                <div className="row justify-content-center mt-2">
                  <div
                    className="col-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      background: "red",
                    }}
                  ></div>
                  <span className="col-4">Sin energía solar</span>
                  <div
                    className="col-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      background: "#7dc150",
                    }}
                  ></div>
                  <span className="col-4">Con energía solar</span>
                </div>
                <ElectricBill />
              </div>

              <div className="col-md-12 col-lg-6 mt-5">
                <div className="table-example">
                  <table className="table table-striped table-bordered">
                    <tbody>
                      <tr>
                        <td>Producción anual de energía eléctrica</td>
                        <td>115.000 kWh/año</td>
                      </tr>
                      <tr>
                        <td>Costo de inversión</td>
                        <td>$79.900.000 más IVA</td>
                      </tr>
                      <tr>
                        <td>Ahorro de CO2 al ambiente</td>
                        <td>70 ton métricas</td>
                      </tr>
                      <tr>
                        <td>Garantía paneles</td>
                        <td>25 años</td>
                      </tr>
                      <tr>
                        <td>Retorno de la Inversión (ROI)</td>
                        <td>5,5 años</td>
                      </tr>
                      <tr>
                        <td>Tasa Interna de Retorno (TIR)</td>
                        <td>18%</td>
                      </tr>
                    </tbody>
                  </table>
                  **Los datos del ejemplo son sólo referenciales. Consulta con
                  nuestros ejecutivos de ventas para conocer los datos
                  personalizados para tu negocio.
                </div>
              </div>
            </Slide>

            <Slide id="visita" className="small" isLastSlide={true}>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="text-center">
                  <h2>
                    Transforma a la energía solar en tu <b>ventaja</b>{" "}
                    competitiva
                  </h2>
                  <h4>Diseñando tu propia planta solar.</h4>
                  <a href="\#" className="btn btn-primary" onClick={this.click}>
                    Ingresar
                  </a>
                </div>
              </div>
            </Slide>
          </div>
        </section>

        <Footer />
      </>
    )
  }
}

function Page(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          jumbo: file(relativePath: { eq: "empresas.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          lideres: file(relativePath: { regex: "/lideres.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          internacional: file(relativePath: { regex: "/internacional.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={data => (
        <Empresas
          jumbo={data.jumbo}
          lideres={data.lideres}
          internacional={data.internacional}
          {...props}
        />
      )}
    />
  )
}
export default Page
